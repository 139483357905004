import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import { Page } from '../components/layout';

import Person from './team/person';
import HighlightYouTube from './shared/highlight-youtube';

import styles from './team.module.scss';

const Team = ({
  data: {
    markdownRemark: {
      frontmatter: { people, videos },
      fields: { slug },
      html
    }
  }
}) => (
  <Page slug={slug} body={html} showContact={false}>
    <div className={styles.wrapper}>

      <div className={styles.people}>
        {people.map(({ name, role, phone, email, text, image, image_hover }, index) => (
          <Fragment key={index}>
            <Person
              name={name}
              role={role}
              phone={phone}
              email={email}
              text={text}
              image={image}
              image_hover={image_hover}
            />
          </Fragment>
        ))}
      </div>

      {Array.isArray(videos) &&
        videos.map((uuid, index) => (
          <div key={index} className={styles.video}>
            <HighlightYouTube uuid={uuid} />
          </div>
        ))}
    </div>
  </Page>
);

export const teamQuery = graphql`
  query teamQuery($remarkId: String!) {
    markdownRemark(id: { eq: $remarkId }) {
      frontmatter {
        people {
          name
          role
          phone
          email
          text
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image_hover {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        videos
      }
      fields {
        slug
      }
      html
    }
  }
`;

export default Team;
